img.avatar {
  background-size: 100%;
  display: block;
  height: 150px;
  overflow: hidden;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  width: 150px;
  margin-right: 15px;
}
